import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const RECAPTCHA_SITE_KEY = "6Lea82oqAAAAAMm0qPWIOvHmN--Fg_5AqT8k5l67";

const root = ReactDOM.createRoot(document.getElementById('input-script'));
root.render(
  <React.StrictMode>
	<GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
    	<App />
	</GoogleReCaptchaProvider>
  </React.StrictMode>
);
