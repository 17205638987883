import React, { useState, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios from 'axios';
import './App.css';

function App() {
  const [handle, setHandle] = useState('');
  const [didPlc, setDidPlc] = useState('');
  const [message, setMessage] = useState('');
  
  const [recaptchaToken, setRecaptchaToken] = useState(''); // Store the token here
  const [isReCaptchaReady, setIsReCaptchaReady] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Handle the data inputs for the form
  const handleInputChange = (e) => {
    setHandle(e.target.value.toLowerCase());
  };
  const didPlcInputChange = (e) => {
    setDidPlc(e.target.value.toLowerCase());
  };

  // Check and update reCAPTCHA readiness
  useEffect(() => {
    if (executeRecaptcha) {
      setIsReCaptchaReady(true);
    }
  }, [executeRecaptcha]);

  // Generate reCAPTCHA token periodically when reCAPTCHA is ready
  useEffect(() => {
    const getRecaptchaToken = async () => {
      if (isReCaptchaReady && executeRecaptcha) {
        try {
          const token = await executeRecaptcha('verifyHandle');
          setRecaptchaToken(token);
        } catch (error) {
          console.error('Failed to execute reCAPTCHA:', error);
        }
      }
    };

    // Generate the token once when the component mounts
    getRecaptchaToken();

    // Set an interval to renew the token every 2 minutes
    const interval = setInterval(() => {
      getRecaptchaToken();
    }, 120000); // 2 minutes in milliseconds

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [isReCaptchaReady, executeRecaptcha]);

  // On form submit processing
  const handleSubmit = async () => {
    
    // Did we get any handle entry?
    if (!handle) {
      handleErrorResponse('BLANK_HANDLE');
      return;
    }
    
    // Check we're not exceeding the alphanumeric 20 character max limit
    if(!/^[a-zA-Z0-9]{1,20}$/.test(handle)) {
      handleErrorResponse('INVALID_HANDLE');
      return;
    }
    
    // Did we get any did:plc entry?
    if (!didPlc) {
      handleErrorResponse('BLANK_DID_PLC');
      return;
    } 
    
    // Is it a valid bluesky did:plc value?
    // TODO: sanity check against whether the profile actually exists to stop idiots like Raigho from trying to break it with random bot level shit
    if (!/^did:plc:[a-zA-Z0-9]{24}$/.test(didPlc)) {
      handleErrorResponse('INVALID_DID_PLC');
      return
    }
    
    // check if the token was retrieved successfully
    if (!recaptchaToken) {
        handleErrorResponse('RECAPTCHA_FAILED'); // Handle token failure
        return;
    }

    try {	  
      const response = await axios.post('https://wolfbear.club/verifyHandle', {
        handle,
        didPlc,
        recaptchaValue: recaptchaToken, // Send the reCAPTCHA token
      });

      const data = response.data;

      if (data.success) {
        setMessage('Verification Complete! Now head over to bluesky and hit "Verify Text File"!');
        setHandle('');
        setDidPlc('');
      } else {
        handleErrorResponse(data.error);
      }
    } catch (error) {
      handleErrorResponse();
    }
  };

  const handleErrorResponse = (error) => {
    switch (error) {
      case 'BLANK_HANDLE':
        setMessage('Please enter a handle you would like to use.');
        break;
      case 'INVALID_HANDLE':
        setMessage('Invalid handle. Must be alphanumeric (letters and numbers only) and no longer than 20 characters.');
        break;
      case 'BLANK_DID_PLC':
        setMessage('Please enter your did:plc provided from bluesky. If you need help, please see below.');
        break;
      case 'INVALID_DID_PLC':
        setMessage('Invalid bluesky did:plc code.');
        break;
      case 'RECAPTCHA_UNAVAILABLE':
        setMessage('reCAPTCHA not available.');
        break;
      case 'RECAPTCHA_FAILED':
        setMessage('reCAPTCHA verification error.');
        break;
	    case 'HANDLE_TAKEN':
	      setMessage('Sorry this handle is already in use. Please choose another.');
		    break;
      case 'FILE_WRITE_ERROR':
        setMessage('An error occurred while verifying your handle.');
        break;
      default:
        setMessage('An error occurred that was not handled. HANDLE IT!');
        break;
    }
  };
  
  return (
                    <div>
                        <div className="mb-4 text-center"><label className="form-label staatliches">Choose your handle</label><span className="wolfbear-club staatliches red at">&#64;</span><input type="text" className="form-input w-handle" value={handle} onChange={handleInputChange} maxLength={24} pattern="[a-zA-Z0-9]+" /><span className="wolfbear-club staatliches">.<span className="red">wolfbear</span>.<span className="red">club</span></span></div>
                        <div className="mb-4 text-center"><label className="form-label">Enter your Bluesky DID:PLC CODE</label><input type="text" className="form-input w-100" value={didPlc} onChange={didPlcInputChange} maxLength={32} pattern="^did:plc:[a-zA-Z0-9]+$" /><span className="help">( <a href="#how-to">What is my DID:PLC code?</a> )</span></div>
                        <div className="text-center"><button className="btn btn-primary" type="button" onClick={handleSubmit}>Start verification</button></div>
                        <div className="form-code-messages my-4 py-2 text-center"><span>{message}</span></div> 
                   </div>
  );
}

export default App;
